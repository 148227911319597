






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';

@Component({
  components: {},
})
export default class LandingPageBenefit extends Vue {}
