






















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import { submitContactUs } from '@/apis/customer.api';
import {
  successPopupHandler,
  popupLoadings,
  errorPopupHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';

@Component({
  components: {},
})
export default class ContactUs extends Vue {
  contactUsForm = {
    email: '',
    name: '',
    message: '',
  };

  loadings = {
    submitContactUs: false,
  };

  @loadings('loadings.submitContactUs')
  onSubmitContactUs() {
    return submitContactUs({
      ...this.contactUsForm,
    })
      .then(data => {
        this.$popupSuccess('Successfully. Your ticket id is ' + data.ticket_id);
      })
      .catch(error => {
        this.$popupError(error.description);
      });
  }
}
