import { http } from './http';

export function submitContactUs({email, name, message}) {
    return http.callApi({
        resource: 'customer.contactus',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          email,
          name,
          message
        },
      }
    })
    .then(e => e.data);
}