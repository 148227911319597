
























import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountRegisterService from './AccountRegisterService.vue';
import WebhookNotiCryptor from './WebhookNotiCryptor.vue';
import CrypitorFeatures from './CrypitorFeatures.vue';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

@Component({
  components: {
    AccountRegisterService,
    WebhookNotiCryptor,
    swiper,
    swiperSlide,
    CrypitorFeatures
  },
})
export default class MastHead extends Vue {
  get swiperOption(): Object {
    return {
      slidesPerView: 'auto',
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: true,
      autoplay: true,
      centeredSlides: false,
      allowSlidePrev: true,
      allowSlideNext: true,
      pagination: {
        el: '.swiper-pagination',
        //dynamicBullets: true,
        clickable: true,
      },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev'
      // }
    };
  }
}
