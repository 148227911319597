




















import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import ContactUs from '@/components/shared/ContactUs.vue';
import LandingPageBenefit2 from './LandingPageBenefit2.vue';
import LandingPageHowTo from './LandingPageHowTo.vue';
import LandingPageBenefit from './LandingPageBenefit.vue';
import LandingPageWhy from './LandingPageWhy.vue';
import MastHead from './components/MastHead.vue';

@Component({
  components: {
    ContactUs,
    LandingPageBenefit,
    LandingPageBenefit2,
    LandingPageHowTo,
    LandingPageWhy,
    MastHead,
  },
})
export default class LandingPage extends Vue {}
