import { render, staticRenderFns } from "./CrypitorFeatures.vue?vue&type=template&id=c386452c&scoped=true&lang=pug&"
import script from "./CrypitorFeatures.vue?vue&type=script&lang=ts&"
export * from "./CrypitorFeatures.vue?vue&type=script&lang=ts&"
import style0 from "./CrypitorFeatures.vue?vue&type=style&index=0&id=c386452c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c386452c",
  null
  
)

export default component.exports